exports.components = {
  "component---src-pages-aneis-estaticos-e-enchimentos-calandrados-js": () => import("./../../../src/pages/aneis-estaticos-e-enchimentos-calandrados.js" /* webpackChunkName: "component---src-pages-aneis-estaticos-e-enchimentos-calandrados-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-canal-denuncias-js": () => import("./../../../src/pages/canal-denuncias.js" /* webpackChunkName: "component---src-pages-canal-denuncias-js" */),
  "component---src-pages-certificados-js": () => import("./../../../src/pages/certificados.js" /* webpackChunkName: "component---src-pages-certificados-js" */),
  "component---src-pages-componentes-moldados-js": () => import("./../../../src/pages/componentes-moldados.js" /* webpackChunkName: "component---src-pages-componentes-moldados-js" */),
  "component---src-pages-componentes-para-linha-distribuicao-js": () => import("./../../../src/pages/componentes-para-linha-distribuicao.js" /* webpackChunkName: "component---src-pages-componentes-para-linha-distribuicao-js" */),
  "component---src-pages-conjuntos-flexiveis-cobre-e-blindagens-eletrostaticas-js": () => import("./../../../src/pages/conjuntos-flexiveis-cobre-e-blindagens-eletrostaticas.js" /* webpackChunkName: "component---src-pages-conjuntos-flexiveis-cobre-e-blindagens-eletrostaticas-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-isolantes-para-bobinas-js": () => import("./../../../src/pages/isolantes-para-bobinas.js" /* webpackChunkName: "component---src-pages-isolantes-para-bobinas-js" */),
  "component---src-pages-isolantes-para-nucleo-e-ligacao-parte-ativa-js": () => import("./../../../src/pages/isolantes-para-nucleo-e-ligacao-parte-ativa.js" /* webpackChunkName: "component---src-pages-isolantes-para-nucleo-e-ligacao-parte-ativa-js" */),
  "component---src-pages-politicas-js": () => import("./../../../src/pages/politicas.js" /* webpackChunkName: "component---src-pages-politicas-js" */),
  "component---src-pages-produtos-index-js": () => import("./../../../src/pages/produtos/index.js" /* webpackChunkName: "component---src-pages-produtos-index-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */)
}

